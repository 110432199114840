import axios from '@/common/js/request'

export function bzWorkOrderCheck_pageProcess(data){
    const bzWorkOrderCheck_pageProcess = axios({
        url: '/bzWorkOrderCheck/pageProcess',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_pageProcess
}
export function bzWorkOrderCheck_getProcessRecord(data){
    const bzWorkOrderCheck_getProcessRecord = axios({
        url: '/bzWorkOrderCheck/getProcessRecord',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_getProcessRecord
}
export function bzWorkOrderCheck_getProcessDetail(data){
    const bzWorkOrderCheck_getProcessDetail = axios({
        url: '/bzWorkOrderCheck/getProcessDetail',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_getProcessDetail
}
export function inspectorDropDown(data){
    const inspectorDropDown = axios({
        url: '/AaumArchive/inspectorDropDown',
        method: 'get',
        params:data
    })
    return inspectorDropDown
}
// 任务分页列表
export function taskPageList(data){
    const taskPageList = axios({
        url: '/aaumTask/taskPageList',
        method: 'get',
        params:data
    })
    return taskPageList
}
// 任务分页列表
export function taskDeleteById(data){
    const taskDeleteById = axios({
        url: '/aaumTask/taskDeleteById',
        method: 'get',
        params:data
    })
    return taskDeleteById
}