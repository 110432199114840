<template>
    <div>
     <el-dialog
      title="查看详情"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
      @open="opDialog"
    >
     
      <el-form ref="addform" :model="form" label-width="120px" size="mini" >
        <div v-if="form.workOrderInfo">
          <el-form-item label="用气设备:" prop="positionName">
           {{form.workOrderInfo.equipmentCodeName}}
          </el-form-item>
          <el-form-item label="执行位置:" prop="positionName">
           {{form.workOrderInfo.address}}
          </el-form-item>
          <el-form-item label="测量员:" prop="positionName">
           {{form.workOrderInfo.surveyorName}}
          </el-form-item>
          <el-form-item label="执行简图:" prop="positionName" v-if="form.workOrderInfo.imageUrlList.length>0">
           <el-image 
                style="width: 100px; height: 100px"
                :src="form.workOrderInfo.imageUrlList[0]" 
                :preview-src-list="form.workOrderInfo.imageUrlList">
            </el-image>
            <span style="margin-left:10px">共{{form.workOrderInfo.imageUrlList.length}}张</span>
          </el-form-item>
          <!-- <el-form-item label="创建人:" prop="positionName">
           {{form.solveStatusTime}}
          </el-form-item> -->
          <el-form-item label="创建时间:" prop="positionName">
           {{form.workOrderInfo.createTime}}
          </el-form-item>
        </div>
        <div v-if="form.checkResult">
           
          <el-form-item label="审核结果:" prop="positionCode">
            {{form.checkResult.checkTypeName}}
          </el-form-item>
          <el-form-item label="审核建议:" prop="positionCode" v-if="form.checkResult.checkType==1">
            {{form.checkResult.remark}}
          </el-form-item>
          <el-form-item label="驳回原因:" prop="positionCode" v-if="form.checkResult.checkType==2">
            {{form.checkResult.remark}}
          </el-form-item>
         
        </div>
        <div v-if="form.priceChange">
           
          <el-form-item label="预算价格:" prop="positionCode">
            {{form.priceChange.budgetPrice}}
          </el-form-item>
          <el-form-item label="申请价格:" prop="positionCode">
            {{form.priceChange.applyPrice}}
          </el-form-item>
          <el-form-item label="申请原因:" prop="positionCode">
            {{form.priceChange.remark}}
          </el-form-item>
         
        </div>
        <div v-if="form.stopInfo">
           
         
          <el-form-item label="终止环节:" prop="positionCode">
            {{form.stopInfo.nodeName}}
          </el-form-item>
          <el-form-item label="终止原因:" prop="positionCode">
            {{form.stopInfo.remark}}
          </el-form-item>
         
        </div>
        <div  v-if="form.designInfo">
           <el-form-item label="设计图：" prop="positionCode">
           <el-image 
                style="width: 100px; height: 100px"
                :src="form.designInfo.imageUrlList[0]" 
                :preview-src-list="form.designInfo.imageUrlList">
            </el-image>
            <span style="margin-left:10px">共{{form.designInfo.imageUrlList.length}}张</span>
          </el-form-item>
          <el-form-item label="补充说明:" prop="positionCode">
            {{form.designInfo.remark}}
          </el-form-item>
        </div>
        <div v-if="form.budgetInfo">
          <el-form-item label="预算价格:">
            <span style="word-break:break-all">{{form.budgetInfo.budgetPrice}}</span>
          </el-form-item>
          <el-form-item label="设计图：">
            <el-image 
                style="width: 100px; height: 100px"
                :src="form.budgetInfo.imageUrlList[0]" 
                :preview-src-list="form.budgetInfo.imageUrlList">
            </el-image>
            <span style="margin-left:10px">共{{form.budgetInfo.imageUrlList.length}}张</span>
          </el-form-item>
          <el-form-item label="补充说明:">
            <span style="word-break:break-all">{{form.budgetInfo.remark}}</span>
          </el-form-item>
        </div>
        <div v-if="form.reason">
          <el-form-item :label="type==14?'作废原因:':'申请原因:'" >
            <span style="word-break:break-all">{{form.reason}}</span>
          </el-form-item>
        </div>
        <div v-if="form.userSignDetailVO">
          <el-form-item label="合同类型:">
            <span style="word-break:break-all">{{form.userSignDetailVO.contractTypeName}}</span>
          </el-form-item>
          <el-form-item label="用气类型:">
            <span style="word-break:break-all">{{form.userSignDetailVO.typeName}}</span>
          </el-form-item>
          <el-form-item label="合同编号:" >
            <span style="word-break:break-all">{{form.userSignDetailVO.contractNumber}}</span>
          </el-form-item>
          <el-form-item label="合同附件:">
            <el-image 
                style="width: 100px; height: 100px"
                :src="form.userSignDetailVO.contractUrlList[0]" 
                :preview-src-list="form.userSignDetailVO.contractUrlList">
            </el-image>
            <span style="margin-left:10px">共{{form.userSignDetailVO.contractUrlList.length}}张</span>
          </el-form-item>
          <el-form-item label="缴费佐证:">
            <el-image 
                style="width: 100px; height: 100px"
                :src="form.userSignDetailVO.paymentUrlList[0]" 
                :preview-src-list="form.userSignDetailVO.paymentUrlList">
            </el-image>
            <span style="margin-left:10px">共{{form.userSignDetailVO.paymentUrlList.length}}张</span>
          </el-form-item>
          <el-form-item label="签约说明:" >
            <span style="word-break:break-all">{{form.userSignDetailVO.remark}}</span>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" >
        <!-- <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button> -->
        </div>
    </el-dialog>
  </div>
</template>
<script>
import {bzWorkOrderCheck_getProcessRecord} from '../../RequestPort/selectQuery/select'
export default {
  components: { },
  name: 'applyList',
  mixins: [],
  data() {
    return {
      dialogVisible:false,
      title:'',
      form:{workOrderInfo:{imageUrlList:[]},designInfo:{imageUrlList:[]},budgetInfo:{imageUrlList:[]}}
    }
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
    }
  },
  mounted() {
      
  },
  methods: {
      opDialog(){
        this.clearnF();
        if(this.$nextTick){
                bzWorkOrderCheck_getProcessRecord({id:this.id}).then(res=>{
                this.form = res.data;
                if(!this.form.workOrderInfo.imageUrlList){
                    this.form.workOrderInfo.imageUrlList = []
                }
                 if(!this.form.designInfo.imageUrlList){
                    this.form.designInfo.imageUrlList = []
                }
                if(!this.form.budgetInfo.imageUrlList){
                    this.form.budgetInfo.imageUrlList = []
                }
                })
            
        }
      },
      clearnF(){
        this.form={workOrderInfo:{imageUrlList:[]},designInfo:{imageUrlList:[]},budgetInfo:{imageUrlList:[]}}
    },
      
      setdialogVisible(flag){
          this.dialogVisible = flag;
      }
  }
}
</script>
<style scoped>
.icon_line{
  width: 3px;
height: 16px;
background: #1082FF;
display: inline-block;
}
.title{
  font-weight: 400;font-size: 16px; margin-left: 5px;height: 16px; line-height: 16px;
}
</style>
